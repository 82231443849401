import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class StockCounterProvider extends HttpRequest {
  getByStockCountId (stockCountId) {
    this.setHeader(getAuthToken())
    return this.get(`stock-counter/stock-count-id/${stockCountId}`)
  }

  getByStockCounterId (id) {
    this.setHeader(getAuthToken())
    return this.get(`stock-counter/${id}`)
  }
}

export default StockCounterProvider
