<template>
  <div>
    <v-sheet
      v-if="stockCountData"
      class="pa-8">
      <v-row>
        <v-col cols="12">
          <div class="d-flex align-center">
            <v-icon @click="goBack">
              mdi-arrow-left
            </v-icon>
            <p class="text-h5 mb-0 ml-3">
              รายละเอียดใบสั่งนับ
            </p>
          </div>
        </v-col>

        <v-col cols="6">
          <p>
            <span class="font-weight-bold mr-2">Code : </span> <span class="mr-2">{{ stockCountData.code || '-'
            }}</span>
            <span class="font-weight-bold mr-2">Status : </span>
            <span :class="`${getStatusLabel(stockCountData.status, 'color')}--text`">
              {{ getStatusLabel(stockCountData.status, 'label') }}
            </span>
          </p>
          <p class="mb-0">
            <span class="font-weight-bold mr-2">วันที่ต้องนับ : </span> <span class="mr-2">{{ stockCountData.countDate |
              dateUserFormat }}</span>
            <span class="font-weight-bold mr-2">Location : </span> <span>{{ stockCountData.warehouse.name || '-'
            }}</span>
          </p>
        </v-col>
        <v-col
          class="d-flex justify-end align-end"
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6">
          <v-dialog
            v-model="dialog"
            width="60%">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-3"
                v-bind="attrs"
                v-on="on">
                Notes
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5">
                Notes
              </v-card-title>

              <Notes
                :notes="stockCountData.notes"
                :add-note-loading="addNoteLoading"
                @addNote="addNote" />
            </v-card>
          </v-dialog>
          <v-btn
            v-if="!isLockedDoc"
            color="secondary"
            class="mr-3"
            :loading="loadingCalculate"
            @click="openCreateCalculateDialog">
            Calculate with stock
          </v-btn>
          <v-btn
            v-if="stockCountData.status === 'waiting' && stockAdjustments.length"
            class="mr-3"
            color="red"
            dark
            :loading="loadingUpdateStatus"
            @click="openStatusDialog">
            Completed
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <p class="text-h6">
            รายการนับ
          </p>
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            class="elevation-1 pa-3"
            :loading="loadingStockCounter"
            loading-text="Loading... Please wait">
            <template v-slot:header.select="{}">
              <v-checkbox
                v-if="!isLockedDoc && items.length"
                v-model="headerSelect"
                color="secondary"
                hide-details
                class="shrink mr-2 mt-0"
                @change="onSelectAllChange"></v-checkbox>
              <p
                v-else
                class="mb-0">
                Select
              </p>
            </template>
            <template v-slot:item.select="{ item }">
              <v-checkbox
                v-if="!isLockedDoc"
                v-model="selectedItems"
                :value="item"
                color="secondary"
                hide-details
                class="shrink mr-2 mt-0"></v-checkbox>
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ item.createdAt | dateTimeUserFormat }}
            </template>
            <template v-slot:item.calStatus="{ item }">
              <p
                v-if="checkIsCalculated(item.counterCode)"
                class="mb-0 success--text">
                คำนวณแล้ว
              </p>
              <p
                v-else
                class="mb-0 orange--text">
                ยังไม่คำนวณ
              </p>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                class="mr-3"
                :to="{ name: 'StockCounterResult', params: { stockCountId: $route.params.id, counterId: item.id } }"
                text
                color="indigo">
                <span class="text-decoration-underline">สินค้าที่นับได้</span>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet class="pa-8 mt-8">
      <v-row>
        <v-col cols="12">
          <p class="text-h6">
            รายการที่คำนวณกับ stock แล้ว
          </p>
          <v-data-table
            :headers="stockAdjustmentsHeaders"
            :items="stockAdjustments"
            hide-default-footer
            class="elevation-1 pa-3"
            :loading="loadingStockAdjustment"
            loading-text="Loading... Please wait">
            <template v-slot:item.stockTime="{ item }">
              {{ item.stockTime | dateTimeUserFormat }}
            </template>
            <template v-slot:item.status="{ item }">
              <span :class="`${getStatusLabel(item.status, 'color')}--text`">
                {{ getStatusLabel(item.status, 'label') }}
              </span>
            </template>
            <template v-slot:item.stockCounters="{ item }">
              {{ item.stockCounters.map((ct) => ct.stockCounterCode).join(', ') }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                class="mr-3"
                color="indigo"
                :to="{ name: 'StockAdjustmentPreview', params: { stockCountId: $route.params.id, stockAdjustmentId: item.id } }"
                text>
                <span class="text-decoration-underline">ผลลัพธ์การเปรียบเทียบ</span>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet class="pa-8 mt-8">
      <v-row>
        <v-col cols="12">
          <p class="text-h6">
            รายการใบนำเข้า-นำออก
          </p>
          <v-data-table
            :headers="noteHeaders"
            :items="noteWithStatus"
            hide-default-footer
            class="elevation-1 pa-3"
            :loading="loadingStockAdjustment"
            loading-text="Loading... Please wait">
            <template v-slot:item.status="{ item }">
              <span :class="`${getNoteStatusLabel(item.status, 'color')}--text`">
                {{ getNoteStatusLabel(item.status, 'label') }}
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                :key="item.id"
                text
                color="indigo"
                @click="item.nt === 'dn'
                  ? openNewTab({ name: 'DeliveryNote', query: { id: item.id, warehouse: stockCountData.warehouse.id } })
                  : openNewTab({ name: 'WithdrawNote', query: { id: item.id, warehouse: stockCountData.warehouse.id } })">
                <span class="text-decoration-underline">{{ item.code }}</span>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import StockCountProvider from '@/resources/StockCountProvider'
import StockCounterProvider from '@/resources/StockCounterProvider'
import StockCountAdjustmentProvider from '@/resources/StockCountAdjustmentProvider'
import InventoryProvider from '@/resources/InventoryProvider'
import Notes from '../components/Notes.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    Notes
  },
  data () {
    return {
      stockCountProvider: new StockCountProvider(),
      stockCounterProvider: new StockCounterProvider(),
      stockCountAdjustmentProvider: new StockCountAdjustmentProvider(),
      inventoryProvider: new InventoryProvider(),
      stockCountData: null,
      loadingStockCount: false,
      loadingStockAdjustment: false,
      loadingStockCounter: false,
      loadingCalculate: false,
      addNoteLoading: false,
      status: [
        { value: '', label: 'ทั้งหมด', color: '' },
        { value: 'ready', label: 'พร้อมนับ', color: 'success' },
        { value: 'waiting', label: 'รอตรวจสอบ', color: 'indigo' },
        { value: 'completed', label: 'เสร็จสิ้น', color: 'grey' }
      ],
      noteStatus: [
        { value: 'pending', label: 'PENDING', color: 'orange' },
        { value: 'approved', label: 'APPROVED', color: 'success' },
        { value: 'canceled', label: 'CANCELED', color: 'gray' },
        { value: 'draft', label: 'DRAFT', color: 'gray' }
      ],
      items: [],
      dialog: false,
      dialogDocStatus: false,
      headers: [
        {
          text: 'Select',
          align: 'left',
          value: 'select',
          sortable: false,
          width: '30'
        },
        {
          text: 'Code',
          align: 'left',
          value: 'counterCode',
          sortable: false
        },
        {
          text: 'Remark / Location',
          align: 'left',
          value: 'remark',
          sortable: false
        },
        {
          text: 'Status',
          align: 'center',
          value: 'calStatus',
          sortable: false
        },
        {
          text: 'Commit Time',
          align: 'center',
          value: 'createdAt',
          sortable: false
        },
        {
          text: 'Commit by',
          align: 'center',
          value: 'createdBy.email',
          sortable: false
        },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false }
      ],
      stockAdjustments: [],
      stockAdjustmentsHeaders: [
        {
          text: 'Code',
          align: 'left',
          value: 'code',
          sortable: false
        },
        {
          text: 'Counter code',
          align: 'left',
          value: 'stockCounters',
          sortable: false
        },
        {
          text: 'Stock Time',
          align: 'center',
          value: 'stockTime',
          sortable: false
        },
        {
          text: 'Created by',
          align: 'center',
          value: 'createdBy.email',
          sortable: false
        },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false }
      ],
      noteHeaders: [
        {
          text: 'Code',
          align: 'left',
          value: 'code',
          sortable: false
        },
        {
          text: 'Type',
          align: 'center',
          value: 'noteType',
          sortable: false
        },
        {
          text: 'Note status',
          align: 'center',
          value: 'status',
          sortable: false
        },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false }
      ],
      isLockedDoc: true,
      noteWithStatus: [],
      loadingUpdateStatus: false,
      headerSelect: false,
      selectedItems: []
    }
  },
  watch: {
    selectedItems: {
      handler (val) {
        if (val.length > 0 && val.length === this.items.length) {
          this.headerSelect = true
        } else {
          this.headerSelect = false
        }
      },
      deep: true
    }
  },
  async mounted () {
    this.init()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal'
    }),
    async init () {
      this.headerSelect = false
      this.selectedItems = []
      this.items = []
      await this.fetchStockCountById(this.$route.params.id)
      await this.fetchStockAdjustmentByStockCountId(this.$route.params.id)
      this.fetchStockCounterByStockCountId(this.$route.params.id)
    },
    getStatusLabel (status, attribute = 'label') {
      return this.status.find((st) => st.value === status)[attribute] ?? '-'
    },
    getNoteStatusLabel (status, attribute = 'label') {
      return this.noteStatus.find((st) => st.value === status)[attribute] ?? '-'
    },
    async fetchStockCountById (id, onlyNoteUpdate = false) {
      this.loadingStockCount = true
      try {
        const { data } = await this.stockCountProvider.getById(id)
        if (data && !onlyNoteUpdate) {
          this.stockCountData = data
        } else if (data && onlyNoteUpdate) {
          this.stockCountData.notes = []
          this.stockCountData.notes = data.notes
        }
      } catch (error) {
        console.error('fetchStockCountById', error)
      } finally {
        this.loadingStockCount = false
      }
    },
    async fetchStockCounterByStockCountId (stockCountId) {
      this.loadingStockCounter = true
      try {
        const { data } = await this.stockCounterProvider.getByStockCountId(stockCountId)
        this.items = data ?? []
      } catch (error) {
        console.error('fetchStockCountById', error)
      } finally {
         this.loadingStockCounter = false
      }
    },
    async fetchStockAdjustmentByStockCountId (stockCountId) {
      this.loadingStockAdjustment = true
      try {
        const { data } = await this.stockCountAdjustmentProvider.getByStockCountId(stockCountId)
        this.stockAdjustments = data ?? []
        if (this.stockAdjustments.length) {
          this.isLockedDoc = await this.checkNoteStatus()
        } else {
          this.isLockedDoc = false
        }
      } catch (error) {
        console.error('fetchStockCountById', error)
      } finally {
        this.loadingStockAdjustment = false
      }
    },
    async addNote (note) {
      if (!note) return
      this.addNoteLoading = true
      try {
        await this.stockCountProvider.addNote(this.stockCountData.id, { note })
        await this.fetchStockCountById(this.stockCountData.id, true)
      } catch (error) {
        console.error('addNote', error)
      }
      this.addNoteLoading = false
    },
    onSelectAllChange (changeVal) {
      if (changeVal) {
        this.selectedItems = []
        this.selectedItems = [...this.items]
      } else {
        this.selectedItems = []
      }
    },
    async createCalculateStockAdjustment (stockCounters) {
      this.loadingCalculate = true
      try {
        const payload = {
          stockCountCode: this.stockCountData.code,
          stockCount: this.stockCountData.id,
          stockCounters,
          warehouse: this.stockCountData.warehouse
        }
        const { data } = await this.stockCountAdjustmentProvider.createStockAdjustment(payload)
        this.$router.push({ name: 'StockAdjustmentPreview', params: { stockCountId: data.stockCountId, stockAdjustmentId: data.stockAdjustmentId } })
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: 'ไม่สามารถคำนวณใบปรับ Stock ได้',
          type: 'error'
        })
      } finally {
        this.loadingCalculate = false
      }
    },
    openCreateCalculateDialog () {
      const stockCounters = this.selectedItems.map((item) => ({
        stockCounterCode: item.counterCode,
        stockCounter: item.id
      }))
      if (this.isLockedDoc) {
        this.setModal({
          value: true,
          title: 'คำนวณใบปรับ Stock',
          message: 'ไม่สามารถคำนวณใบปรับ Stock ใหม่ได้ กรุณาตรวจสอบใบนำเข้า หรือ ใบนำออก',
          confirmText: 'ตกลง',
          confirmType: 'error',
          confirmOnly: true
        })
        return
      }
      if (stockCounters.length) {
        this.setModal({
          value: true,
          title: 'คำนวณใบปรับ Stock',
          message: 'ต้องการคำนวณใบปรับ Stock หรือไม่ ?',
          confirmText: 'ต้องการ',
          confirmType: 'success',
          cancelType: '',
          cancelText: 'ยกเลิก',
          onConfirm: () => this.createCalculateStockAdjustment(stockCounters)
        })
      } else {
        this.setModal({
          value: true,
          title: 'คำนวณใบปรับ Stock',
          message: 'กรุณาเลือกรอบการนับก่อนคำนวณใบปรับ Stock',
          confirmText: 'ตกลง',
          confirmType: 'success',
          confirmOnly: true
        })
      }
    },
    async updateStatus () {
      try {
        await this.stockCountProvider.updateStatusById(this.stockCountData.id, 'completed')
        this.init()
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: 'ไม่สามารถบปรับสถานะได้',
          type: 'error'
        })
      }
    },
    openStatusDialog () {
      this.loadingUpdateStatus = true
      try {
        if (this.stockCountData.status === 'waiting' && this.stockAdjustments.length > 0) {
          this.setModal({
            value: true,
            title: 'ปรับสถานะ',
            message: 'ต้องการปรับสถานะเป็นเสร็จสิ้นหรือไม่ ?',
            confirmText: 'ต้องการ',
            confirmType: 'success',
            cancelType: '',
            cancelText: 'ยกเลิก',
            onConfirm: () => this.updateStatus()
          })
        } else {
          this.setModal({
            value: true,
            title: 'ปรับสถานะ',
            message: 'ไม่สามารถปรับสถานะได้กรุณาตรวจสอบ',
            confirmText: 'ตกลง',
            confirmType: 'success',
            confirmOnly: true
          })
        }
      } catch (error) {
        this.setModal({
          value: true,
          title: 'ปรับสถานะ',
          message: 'ไม่สามารถปรับสถานะได้กรุณาตรวจสอบ',
          confirmText: 'ตกลง',
          confirmType: 'success',
          confirmOnly: true
        })
      } finally {
        this.loadingUpdateStatus = false
      }
    },
    async checkNoteStatus () {
      const stockAdjustmentsData = this.stockAdjustments[0]
      const dnList = stockAdjustmentsData.notes.filter((note) => note.noteType === 'dn').map((note) => note.noteId)
      const wnList = stockAdjustmentsData.notes.filter((note) => note.noteType === 'wn').map((note) => note.noteId)
      const [dnStatus, wnStatus] = await Promise.all([
        this.inventoryProvider.getDeliveryNoteStatus({ ids: dnList }),
        this.inventoryProvider.getWithdrawNoteStatus({ ids: wnList })
      ])
      dnStatus.data = dnStatus.data.map((dn) => ({ ...dn, noteType: 'ใบนำเข้า', nt: 'dn' }))
      wnStatus.data = wnStatus.data.map((wn) => ({ ...wn, noteType: 'ใบนำออก', nt: 'wn' }))
      this.noteWithStatus = [...dnStatus.data, ...wnStatus.data]
      const findDnStatus = dnStatus.data.findIndex((dn) => ['approved', 'pending'].includes(dn.status))
      const findWnStatus = wnStatus.data.findIndex((wn) => ['approved', 'pending'].includes(wn.status))
      return findDnStatus !== -1 || findWnStatus !== -1
    },
    openNewTab ({ name, query }) {
      const routeData = this.$router.resolve({ name, query })
      window.open(routeData.href, '_blank')
    },
    checkIsCalculated (stockCounterCode) {
      return this.stockAdjustments.length
        ? this.stockAdjustments[0].stockCounters.find((ct) => ct.stockCounterCode === stockCounterCode)
        : false
    },
    goBack () {
      if (window.history.length > 1) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'StockCountList' })
      }
    }
  }
}
</script>
