<template>
  <div>
    <v-sheet
      class="pa-8">
      <v-row>
        <v-col
          cols="12"
          style="max-height: calc(100vh - 176px);">
          <v-card
            class="pt-6 pr-6 pb-6"
            style="height: calc(100vh - 176px - 208px - 36px); overflow-y: scroll;">
            <v-timeline
              align-top
              dense>
              <v-timeline-item
                v-for="(noteData, index) in notes"
                :key="index"
                color="secondary"
                small>
                <v-card white>
                  <v-card-text class="white text--primary">
                    <p>
                      <v-icon class="mr-2">
                        mdi-note
                      </v-icon>
                      <strong>{{ noteData.message }}</strong>
                    </p>
                    <p class="text-caption">
                      <v-icon class="mr-2">
                        mdi-clock
                      </v-icon>
                      {{ noteData.createdAt | dateTimeFormat }}
                    </p>
                    <div class="text-caption">
                      <v-icon class="mr-2">
                        mdi-account
                      </v-icon>
                      {{ noteData.createdBy.email }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card>
          <div class="text-center">
            <v-textarea
              v-model="note"
              placeholder="Note here"
              dense
              class="mt-4"
              outlined></v-textarea>
            <v-btn
              :disabled="!note"
              :loading="addNoteLoading"
              color="secondary"
              @click="addNote">
              <v-icon left>
                mdi-plus
              </v-icon>
              ADD
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
export default {
    props: {
        notes: {
            type: Array,
            default: () => []
        },
        addNoteLoading: {
          type: Boolean,
          default: false
        }
    },
  data () {
    return {
      note: ''
    }
  },
  methods: {
    async addNote () {
      if (!this.note) return
        this.$emit('addNote', this.note)
        this.note = ''
    }
  }
}
</script>
